// export const host = 'http://192.168.1.217:7088/v1/'
//Local
// export const host           = 'http://weezi.biz:7089/v1/' 

// QC
export const host           = 'https://qc.facepay.vn/v1/'

// export const host           = 'http://dev.facepay.vn:81/v1/'

//QC
// export const host           = 'https://mpos-qc.facepay.vn/v1/'

//PILLOT
// export const host           = 'https://mpos-beta.facepay.vn/v1/'

//CMC
// export const host           = 'http://pos.facepay.vn/v1/'

//PILOT
// export const host           = 'http://beta.facepay.vn/v1/'
