// export const hostWsLogin    = 'ws://web.wee.vn:81/ws/login'
//Local
// export const hostWsNewPOS   = 'ws://weezi.biz:7089/ws/webConnection'

export const hostWsNewPOS   = 'wss://qc.facepay.vn/ws/webConnection'
export const hostWsLoginStore = 'wss://qc.facepay.vn/ws/mpos/login?token='

//CMC

// export const hostWsLogin    = 'ws://pos.facepay.vn/ws/login'
// export const hostWsNewPOS   = 'ws://pos.facepay.vn/ws/webConnection'